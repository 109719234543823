import { Link } from 'gatsby'
import React from 'react'

/** @jsx jsx */
import { jsx, css, Container, Styled, Text } from 'theme-ui'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'
import { Section } from './sections/section'
import GatsbyImage from 'gatsby-image'
import { BlogPostPreviewGridLatest } from './blog-post-preview-grid-latest'

export const BlogPostPreviewGrid = ({ posts }) => {
  const latestPosts = posts?.slice(0, 4)
  const morePosts = posts?.slice(4, posts.length)

  return (
    <Section>
      <Container
        variant="container.large"
        sx={{
          px: 4,
          py: 0,
          margin: '0 auto',
          [mediaQueries.xl]: {
            px: 6,
          },
          [mediaQueries.xxl]: {
            px: 7,
          }
        }}
      >
       <BlogPostPreviewGridLatest latestPosts={latestPosts} sx={{ mb: 6 }} />
        <div>
          <div
            sx={{
              borderBottom: `1px solid`,
              borderColor: `grey`,
              py: 3,
              mb: 4
            }}
          >
            <h2 sx={{ m: 0, variant: 'text.heading.small' }}>More blog posts</h2>
          </div>
          <div
            sx={{
              [mediaQueries.lg]: {
                display: `grid`,
                gridTemplateColumns: `3fr 1fr`
              }
            }}
          >
            <ul
              sx={{
                listStyle: `none`,
                padding: 0,
                margin: 0
              }}
            >
              {morePosts?.length > 0 &&
                morePosts.map((post, id) => {
                  return (
                    <li key={id}>
                      <div
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                          [mediaQueries.xl]: {
                            flexDirection: `row`,
                          },
                        }}
                      >
                        <div
                          sx={{
                            [mediaQueries.xl]: {
                              flex: 2,
                              mr: 4,
                            },
                          }}
                        >
                          <Link to={post.path} sx={{ textDecoration: `none`, color: `initial` }}>
                            {post.mainImage?.asset?.fluid && (
                              <GatsbyImage
                                fluid={post.mainImage?.asset?.fluid}
                                alt={post.mainImage?.alt}
                              />
                            )}
                          </Link>
                        </div>
                        <div
                          sx={{
                            [mediaQueries.xl]: {
                              flex: `3`,
                            },
                          }}
                        >
                          <Link
                            to={post.path}
                            sx={{
                              textDecoration: `none`,
                              ':hover': {
                                '> *': {
                                  color: 'primary',
                                },
                              },
                            }}
                          >
                            <Styled.h3
                              sx={{ mt: 2, mb: 2, fontWeight: `bold`, fontSize: [4, 4, 4, 4, 5] }}
                            >
                              {post.title}
                            </Styled.h3>
                          </Link>
                          <Styled.p sx={{ mt: 0 }}>{post.excerpt}</Styled.p>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
            <div />
          </div>
        </div>
      </Container>
    </Section>
  )
}
